<template>
  <div class="home IndustrialPark_home">
    <headert></headert>
    <el-row>
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <div class="top-banner">
          <img src="../../assets/image/banner/Park.png" alt="">
        </div>
      </el-col>
    </el-row>
    <navMenu :activeIndex='menuActiveIndex' :menu='menu' :breadcrumb='breadcrumb' />
    <el-row class=" " type="flex" justify="center" align='center'>
      <el-col :xs="23" :sm="23" :md="23" :lg="18" :xl="18">
        <div class="padding-tb70" >
          <div class="WJgroup-titleBox flex">
            <p class="WJgroup-title">{{$t('park.title')}}</p>
          </div>
        </div>
        <div class="flex align-start park_top_Box" >
          <div class="parkLeftBox">
<!--            维佳产业园-->
            <div >
              <router-link tag="a" to="/EntertainmentIndustrialPark" class="noactive activeBox ">
                <span class="font">{{$t('park.href1')}}</span>
              </router-link>
            </div>
            <div>
              <router-link tag="a" to="/EntertainmentMingfang" class="noactive">
                <span class="font">{{$t('park.href2')}}</span>
              </router-link>
            </div>
          </div>
          <div class="parkRightBox flex flex_column justify_center">
            <img class="parkRimg" src="../../assets/image/park/parbuild.png" alt="">
            <h6 :style="{'letter-spacing':lang=='zh'?'6px':'0' }">{{$t('park.experienceTitle')}}</h6>
            <p :style="{'letter-spacing':lang=='zh'?'6px':'0' }">{{$t('park.experienceDesc')}}</p>
          </div>
        </div>

        <div class="flex justify_between parkIntroduceBox align-start">
          <div class="parkIntroduce">
            <template v-for="(item,index) in newsList" >
              <h6 :key="index">{{item.title}}</h6>
              <p>{{item.subtitle}}</p>
            </template>
<!--            <h6>{{$t('park.introduceTitle')}}</h6>-->
<!--            <p>{{$t('park.introduceDesc')}}</p>-->
<!--            <h6>{{$t('park.positioningTitle')}}</h6>-->
<!--            <p>{{$t('park.positioningDesc')}}</p>-->
          </div>
          <div class="parkIntroduceimg">
            <img src="../../assets/image/park/image163.png" alt="">
          </div>
        </div>
        <div>
          <div class="parkTitle">
            <h5>TARGET</h5>
            <h6>{{$t('park.targetTitle')}}</h6>
          </div>
          <ul class="parkTarget flex justify_around">
            <li class="">
              <img src="../../assets/image/park/IndustrialPark1.png" alt="">
              <p>{{$t('park.target1')}}</p>
            </li>
            <li>
              <img src="../../assets/image/park/IndustrialPark2.png" alt="">
              <p>{{$t('park.target2')}}</p>
            </li>
            <li>
              <img src="../../assets/image/park/IndustrialPark3.png" alt="">
              <p>{{$t('park.target3')}}</p>
            </li>
            <li>
              <img src="../../assets/image/park/IndustrialPark5.png" alt="">
              <p>{{$t('park.target4')}}</p>
            </li>
          </ul>
        </div>
      </el-col>
    </el-row>
    <el-row class=" advantageBox" type="flex" justify="center" align='center'>
      <el-col :xs="23" :sm="23" :md="23" :lg="18" :xl="18">
        <div class="parkTitle">
<!--          园区优势-->
          <h5>ADVANTAGE</h5>
          <h6>{{$t('park.advantage')}}</h6>
        </div>
        <ul class="advantageUl flex justify_around align-start">

          <li v-for="item in advantageList" :key="item.id">
            <div>
              <img :src="item.litpic" alt="">
            </div>
            <h6>{{item.title}}</h6>
            <p v-html="item.subtitle"></p>
          </li>
<!--          <li>-->
<!--            <div>-->
<!--              <img src="../../assets/image/park/Rectangle150.png" alt="">-->
<!--            </div>-->
<!--            <h6>{{$t('park.advantagName1')}}</h6>-->
<!--            <p v-html="$t('park.advantagDesc1')"></p>-->
<!--          </li>-->
<!--          <li>-->
<!--            <div>-->
<!--              <img src="../../assets/image/park/Rectangle151.png" alt="">-->
<!--            </div>-->
<!--            <h6>{{$t('park.advantagName2')}}</h6>-->
<!--            <p v-html="$t('park.advantagDesc2')"></p>-->
<!--          </li>-->
<!--          <li>-->
<!--            <div>-->
<!--              <img src="../../assets/image/park/Rectangle152.png" alt="">-->
<!--            </div>-->
<!--            <h6>{{$t('park.advantagName3')}}</h6>-->
<!--            <p>{{$t('park.advantagDesc3')}}</p>-->
<!--          </li>-->
<!--          <li>-->
<!--            <div>-->
<!--              <img src="../../assets/image/park/Rectangle153.png" alt="">-->
<!--            </div>-->
<!--            <h6>{{$t('park.advantagName4')}}</h6>-->
<!--            <p>{{$t('park.advantagDesc4')}}</p>-->
<!--          </li>-->
        </ul>
      </el-col>
    </el-row>
    <el-row class="servceTitle" type="flex" justify="center" align='center'>
      <el-col :xs="23" :sm="23" :md="23" :lg="18" :xl="18">
<!--        园区服务-->
        <div class="parkTitle" >
          <h5>SERVICE</h5>
          <h6>{{$t('park.serviceTitle')}}</h6>
        </div>
      </el-col>
    </el-row>
    <el-row class=" servceBox" type="flex" justify="center" align='center'>
      <el-col :xs="23" :sm="23" :md="23" :lg="18" :xl="18">
        <ul class="flex justify_between servceAll">
          <li>
            <img src="../../../src/assets/image/park/service1.png" alt="">
            <p>{{$t('park.service1')}}</p>
          </li>
          <li>
            <img src="../../../src/assets/image/park/service2.png" alt="">
            <p>{{$t('park.service2')}}</p>
          </li>
          <li>
            <img src="../../../src/assets/image/park/service3.png" alt="">
            <p>{{$t('park.service3')}}</p>
          </li>
          <li>
            <img src="../../../src/assets/image/park/service4.png" alt="">
            <p>{{$t('park.service4')}}</p>
          </li>
          <li>
            <img src="../../../src/assets/image/park/service5.png" alt="">
            <p>{{$t('park.service5')}}</p>
          </li>
          <li>
            <img src="../../../src/assets/image/park/service6.png" alt="">
            <p>{{$t('park.service6')}}</p>
          </li>
        </ul>
      </el-col>
    </el-row>
    <!-- <el-row class=" servceCarBox" type="flex" justify="center" align='center'>
      <el-col :xs="23" :sm="23" :md="23" :lg="18" :xl="18">
        <div class="flex justify_center " style="">
          <ul class="servceCarUl flex_column flex justify_between align-end">
            <li class="flex justify_end">
              <p>汽车进口展销</p>
              <img src="../../../src/assets/image/park/service9.png" alt="">
            </li>
            <li class="flex justify_end">
              <p>新车销售</p>
              <img src="../../../src/assets/image/park/service10.png" alt="">
            </li>
            <li class="flex justify_end">
              <p>二手车交易</p>
              <img src="../../../src/assets/image/park/service11.png" alt="">
            </li>
          </ul>
          <div class="servceCarMiddle flex justify_center flex_column">
            <img src="../../../src/assets/image/park/service8.png" alt="">
            <p>汽车服务</p>
          </div>
          <ul class="servceCarUl flex_column flex justify_between align-start">
            <li class="flex ">
              <img src="../../../src/assets/image/park/service12.png" alt="">
              <p>汽车维修与改装</p>
            </li>
            <li class="flex justify_end">
              <img src="../../../src/assets/image/park/service13.png" alt="">
              <p>汽车租赁</p>
            </li>
            <li class="flex justify_end">
              <img src="../../../src/assets/image/park/service15.png" alt="">
              <p>汽车零部件销售</p>
            </li>
          </ul>
        </div>
      </el-col>
    </el-row> -->
    <div class="characteristicBox">
      <div class="parkTitle">
<!--        园区特色-->
        <h5>CHARACTERISTIC</h5>
        <h6>{{$t('park.characteristicTitle')}}</h6>
      </div>
      <div class="characteristicCon flex justify_between">
        <div class="characteristicLeft">
          <p class="flex justify_center">{{$t('park.characteristic1')}}</p>
        </div>
        <div class="characteristicRight">
          <ul class="flex align-start">
            <li>
              <p class="flex justify_center">{{$t('park.characteristic2')}}</p>
            </li>
            <li class="characteristicli2">
              <p class="flex justify_center">{{$t('park.characteristic3')}}</p>
            </li>
            <li class="characteristicli3">
              <p class="flex justify_center">{{$t('park.characteristic4')}}</p>
            </li>
            <li class="characteristicli4">
              <p class="flex justify_center">{{$t('park.characteristic5')}}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <el-row class="brandSettleIn" type="flex" justify="center" align='center'>
      <el-col :xs="23" :sm="23" :md="23" :lg="18" :xl="18">
<!--        品牌入驻-->
        <div class="parkTitle" style="text-align: left;">
          <h5>BRAND</h5>
          <h6>{{$t('park.brand')}}</h6>
        </div>
        <div class="flex justify_between parkBrand">
          <div class="brand-desc">
          {{typeInfo.idea}}
          </div>
          <ul class=" flex brandSettleIUl">
            <li>
              <img src="../../assets/image/park/Rectangle287.png" alt="">
              <p></p>
            </li>
            <li>
              <img src="../../assets/image/park/Rectangle285.png" alt="">
              <p></p>
            </li>
          </ul>
        </div>

        <div class="brandlistBox">
          <vue-seamless-scroll :data="brandList" :class-option="optionLeft" class="seamless-warp2">
            <ul class="brandlist flex ">
              <li v-for="(item,index) in brandList" :key="index" :data-index="index"><img :src="item.img" alt=""></li>
            </ul>
          </vue-seamless-scroll>
        </div>


      </el-col>
    </el-row>
    <el-row class="parkMoreBox" type="flex" justify="center" align='center'>
      <el-col :xs="23" :sm="23" :md="23" :lg="18" :xl="18">
        <div class="parkMorImgBox">
          <img src="../../assets/image/park/parkimg.png" alt="">
        </div>
      </el-col>
    </el-row>

    <!-- 底部 -->
    <footerb></footerb>
  </div>
</template>

<script>
  import vueSeamlessScroll from 'vue-seamless-scroll'
  export default {
    name: "Home",
    components: {
      vueSeamlessScroll
    },
    data() {
      return {
        menuActiveIndex: '/EntertainmentIndustrialPark',
        menu: {
          name: '产业布局',
          path: '/IndustryHouse',
          list: [{
            name: this.$t('menu.Property'),
            path: '/IndustryHouse',
          }, {
            name: this.$t('menu.park'),
            path: '/EntertainmentIndustrialPark',
          }, {
            name: this.$t('menu.entertainment'),
            path: '/Entertainment',
          }, {
            name: this.$t('menu.Commerce'),
            path: '/IndustryCommerce',
          }]
        },
        breadcrumb: [{
          name: this.$t('menu.layout'),
          path: '/IndustryHouse',
        }, {
          name: this.$t('menu.park'),
          path: '/EntertainmentIndustrialPark',
        }, {
          name: this.$t('park.href1'),
          path: '/EntertainmentIndustrialPark',
        }],
        brandList: [{
            img: require('../../assets/image/park/pbrand.png')
          },
          {
            img: require('../../assets/image/park/pbrand2.png')
          }, {
            img: require('../../assets/image/park/pbrand3.png')
          },
          {
            img: require('../../assets/image/park/pbrand4.png')
          },
          {
            img: require('../../assets/image/park/pbrand5.png')
          }, {
            img: require('../../assets/image/park/pbrand6.png')
          }, {
            img: require('../../assets/image/park/pbrand7.png')
          }, {
            img: require('../../assets/image/park/pbrand8.png')
          }, {
            img: require('../../assets/image/park/pbrand9.png')
          }, {
            img: require('../../assets/image/park/pbrand10.png')
          }, {
            img: require('../../assets/image/park/pbrand11.png')
          }
        ],
        typeid:177,
        advantageTypeid:178,
        newsList:[],
        typeInfo:{},
        advantageList:[]
      };
    },
    computed: {
      lang(){
        return window.localStorage.getItem('language')
      },
      optionLeft() {
        return {
          step: 0.8,
          direction: 2, // 0向下 1向上 2向左 3向右
          limitMoveNum: this.brandList.length, // 开始无缝滚动的数据量 this.dataList.length
          hoverStop: true,
          openTouch: false,

        };
      },
    },
    created() {},
    mounted() {
      new this.$wow.WOW().init()
      this.getData()
    },
    methods: {
      getData(){

        this.refresh=false;
        let api='?m=api&c=v1.Api&a=index&_ajax=1';
        let newsCondition =`ekey=1&typeid=${this.typeid}&page=1&pagesize=2&flag=c&addfields=title_en,seo_description_en,subtitle_en`;
        this.$request.post(api,{
          apiList_1:newsCondition,
          apiType_1:`ekey=1&typeid=${this.typeid}&addfields=idea,title_en,idea_en`,
          apiList_2:`ekey=2&typeid=${this.advantageTypeid}&page=1&pagesize=4&noflag=c&addfields=title_en,seo_description_en,subtitle_en`,
        }).then(res=>{
          this.typeInfo=this.$utils.translate(res.apiType[1].data)
          this.newsList =this.$utils.translate(res.apiList[1].data);
          this.advantageList=this.$utils.translate(res.apiList[2].data);
          console.log(this.advantageList)
        })
      },
      onSwiper(swiper) {
        console.log(swiper);
      },
      onSlideChange() {
        console.log("slide change");
      }
    }
  };
</script>
<style lang="less" scoped>
  @keyframes rotatex {
    0% {
      transform: rotateX(0deg);
    }

    50% {
      transform: rotateX(180deg);
    }

    100% {
      transform: rotateX(180deg);
    }
  }

  .noactive {
    display: inline-block;
    width: 100%;
    height: 116px;
    background: linear-gradient(90deg, #ADAFB2 0%, #8F9294 100%);
    // font-family: 'OPPOSans-M';
    font-style: normal;
    font-weight: 400;
    //line-height: 116px;
    margin-bottom: 1px;
    padding-left: 50px;
    box-sizing: border-box;
    color: #FFFFFF;
    display: flex;
    //justify-content: center;
    align-items: center;

  }
  .font{
    line-height: 36px;
    font-size: 29px;
  }

  .activeBox {
    height: 162px;
    background: linear-gradient(90deg, #E3CAA6 0%, #B5956F 100%);
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 28px;
      right: 0;
      width: 0;
      height: 0;
      border-right: 15px solid #fff;
      border-bottom: 15px solid transparent;
      border-top: 15px solid transparent;
      border-left: 15px solid transparent;
    }
  }

  .swiperIMg {
    width: 100%;
    height: auto;
  }

  .swiper-container {
    width: 100%;
    max-height: 670px;
  }



  .leftTitleOneActive {
    background: linear-gradient(to right, #e3caa6, #b5956f) !important;
    position: relative;
  }

  .leftTitleOneActive::after {
    content: '';
    position: absolute;
    top: 30px;
    right: 0;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-right-color: #fff;
  }

  .leftTitleOne {
    background: linear-gradient(to right, #adafb2, #8f9294);
    // width: 100%;
    padding: 40px 50px;
  }

  .leftTitleOne h4 {
    font-size: 28px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 38px;
  }

  .leftTitleOne h5 {

    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 300;
    color: #FFFFFF;
    line-height: 30px;
  }

  .leftTitleOne h6 {
    margin-top: 20px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 24px;
  }

  .rightBox {
    padding-top: 75px;
  }

  .MfrightBox_info {
    margin-top: 20px;
    margin-left: 55px;
    font-size: 20px;
    line-height: 32px;
    color: #656979;
    text-indent: 2em;
    text-align: justify;
  }



  .parkLeftBox {
    width: 360px;

  }

  .parkRightBox {
    flex: 1;
    position: relative;
    text-align: center;
    min-height: 276px;
    //padding-top: 100px;
  }

  .parkRightBox h6 {
    // font-family: 'OPPOSans';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 52px;
    letter-spacing: 6px;
    color: #333333;
  }

  .parkRightBox p {
    // font-family: 'OPPOSans';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 58px;
    letter-spacing: 6px;
    color: #A4825F;
    margin-top: 36px;
  }

  .parkRimg {
    position: absolute;
    bottom: 0;
    right: -22%;
  }

  .parkIntroduceBox {
    padding-top: 70px;
    padding-bottom: 92px;
    p{
      text-indent: 2em;
    }
  }

  .parkIntroduce {
    width: 40%;
  }

  .parkIntroduce h6 {
    // font-family: 'OPPOSans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 36px;
    color: #333333;
  }

  .parkIntroduce p {
    margin-top: 12px;
    // font-family: 'OPPOSans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #333333;
    margin-bottom: 40px;
  }

  .parkIntroduceimg {
    margin-left: 2%;
    flex: 1;
    padding-top: 31.5%;
    position: relative;
    overflow: hidden;
  }

  .parkIntroduceimg img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  .parkTitle {
    text-align: center;
  }

  .parkTitle h5 {
    // font-family: 'OPPOSans';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 46px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #1F2D39;
  }

  .parkTitle h6 {
    // font-family: 'OPPOSans';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 35px;
    color: #1F2D39;
  }

  .parkTarget {
    text-align: center;
    padding-top: 96px;
    padding-bottom: 156px;
  }

  .parkTarget img {
    width: 86px;
    height: 86px;
  }

  .parkTarget p {
    // font-family: 'OPPOSans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin-top: 23px;

  }

  .advantageBox {
    padding-top: 112px;
    padding-bottom: 130px;
    background: #F2F2F2;
  }

  .advantageUl {
    margin-top: 93px;
  }

  .advantageUl li {
    width: 24%;

  }

  .advantageUl li:hover img {
    transform: scale(1.1);
  }

  .advantageUl li div {
    width: 100%;
    padding-top: 72%;
    position: relative;
    overflow: hidden;
  }

  .advantageUl li div img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transition: 1s;
    // height: 100%;
  }

  .advantageUl li h6 {
    // font-family: 'OPPOSans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #1F2D39;
    text-align: center;
    position: relative;
    margin-top: 19px;
  }

  .advantageUl li h6::after {
    content: "";
    position: absolute;
    bottom: -7px;
    left: 50%;
    margin-left: -15px;
    width: 31px;
    height: 4px;
    background: #1F2D39;
  }

  .advantageUl li p {
    // font-family: 'OPPOSans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin-top: 26px;
    white-space: pre-line;
  }

  .servceTitle {
    padding: 126px 0;
  }

  .servceBox {
    background: url('../../assets/image/park/service14.png') no-repeat;
    background-size: contain;
    width: 100%;
    height: 447px;
  }

  .servceAll {
    height: 100%;
    text-align: center;
  }

  .servceAll li:hover img {}
  .servceAll li{
    flex-shrink: 1;
  }
  .servceAll li img {
    width: 120px;
    height: 120px;
  }

  .servceAll li:hover img {
    -webkit-animation-duration: calc(1s * 0.75);
    animation-duration: calc(1s * 0.75);
    -webkit-animation-duration: calc(var(--animate-duration) * 0.75);
    animation-duration: calc(var(--animate-duration) * 0.75);
    -webkit-animation-name: flipOutX;
    animation-name: flipOutX;
    // -webkit-backface-visibility: visible !important;
    // backface-visibility: visible !important;
  }

  @keyframes flipOutX {
    from {
      -webkit-transform: rotate3d(0, 1, 0, -90deg);
      transform: rotate3d(0, 1, 0, -90deg);
    }

    // 30% {
    // 	-webkit-transform: rotate3d(0, 1, 0, -90deg);
    // 	transform:rotate3d(0, 1, 0, -90deg);

    // }

    to {
      -webkit-transform: rotate3d(0, 1, 0, 90deg);
      transform: rotate3d(0, 1, 0, 90deg);


    }
  }

  .servceAll li p {
    // font-family: 'OPPOSans';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 35px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 30px;
  }

  .servceCarBox {
    background-color: #fff;
    padding-top: 184px;
    padding-bottom: 184px;
  }

  .servceCarUl {
    min-height: 380px;
  }

  .servceCarUl p {
    // font-family: 'OPPOSans';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 35px;
    letter-spacing: 2px;
    color: #000000;
  }

  .servceCarUl img {
    width: 56px;
    height: 56px;
    margin: 0 22px;
  }

  .servceCarMiddle {
    background: url('../../assets/image/park/service7.png') no-repeat;
    background-size: contain;
    width: 380px;
    height: 380px;
    margin: 0 60px;
  }

  .servceCarMiddle img {
    width: 75px;
    height: 75px;
  }

  .servceCarMiddle p {
    //   font-family: 'OPPOSans';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 35px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #000000;
    margin-top: 10px;
  }

  .characteristicBox {
    padding-top: 100px;
    padding-bottom: 75px;
  }

  .characteristicCon {
    margin-top: 122px;
  }

  .characteristicLeft {
    background: url('../../assets/image/park/Rectangle158.png') no-repeat;
    background-size: cover;
    width: 49.5%;
    padding-top: 45%;
    position: relative;
  }

  .characteristicLeft p {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 20%;
    right: 0;
    width: 60%;
    // font-family: 'OPPOSans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    // text-align: center;
    color: #FFFFFF;
    text-indent: 2em;
  }

  .characteristicRight {
    width: 50%;
  }

  .characteristicRight ul {
    flex-wrap: wrap;
  }

  .characteristicRight li {
    width: 50%;
    background: url('../../assets/image/park/Rectangle159.png') no-repeat;
    background-size: cover;
    width: 50%;
    padding-top: 45%;
    position: relative;
  }

  .characteristicRight .characteristicli2 {
    background: url('../../assets/image/park/Rectangle160.png') no-repeat;
    background-size: cover;
  }

  .characteristicRight .characteristicli3 {
    background: url('../../assets/image/park/Rectangle161.png') no-repeat;
    background-size: cover;
  }

  .characteristicRight .characteristicli4 {
    background: url('../../assets/image/park/Rectangle162.png') no-repeat;
    background-size: cover;
  }

  .characteristicRight li p {

    position: absolute;
    top: 0;
    bottom: 0;
    left: 15%;
    right: 0;
    width: 70%;
    // font-family: 'OPPOSans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    // text-align: center;
    color: #FFFFFF;
    text-indent: 2em;
  }

  .brandSettleIn {
    background: url('../../assets/image/park/brandbg.png') no-repeat;
    padding-top: 70px;
    padding-bottom: 52px;
  }
  .brand-desc{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #333333;
    padding-right: 20px;
  }

  .brandSettleIUl li {
    font-size: 0;
    width: 342px;
    margin-left: 26px;
    margin-top: -40px;
    overflow: hidden;
  }

  .brandSettleIUl li:hover img {
    transform: scale(1.1);
  }

  .brandSettleIUl li img {
    width: 100%;
    // height: 30%;
    transition: 1s;
  }

  .brandSettleIUl li p {
    height: 110px;
    width: 100%;
    background: #D5B88E;
  }

  // .seamless-warp2{
  //   width: 100%;
  //   overflow: hidden;
  // }
  .brandlistBox {
    overflow: hidden;
    margin-top: 140px;
    height: 120px;
    margin-bottom: 50px;
  }

  .brandlist {

    // margin-bottom: 180px;
    flex-wrap: nowrap;
    height: 120px;

  }


  .brandlist li {
    width: 240px;
    height: 120px;
    overflow: hidden;
    // margin-bottom: 85px;
  }

  .brandlist li:hover img {
    transform: scale(1.2);
  }

  .brandlist li img {
    width: 100%;
    transition: 0.5s;
    object-fit: cover;
  }

  .parkMoreBox {
    background: url('../../assets/image/park/parkbBG.png') no-repeat;
    background-size: cover;
    padding: 78px 0;
    margin-bottom: 150px;
  }

  .parkMorImgBox {
    width: 100%;
    height: auto;
    text-align: center;
    img{
      width: 100%;
      object-fit: cover;
    }
  }
  @media screen and (min-width:320px) and (max-width:767px) {
 .IndustrialPark_home .park_top_Box{
   display: block;
 }
    .parkLeftBox{
      display: flex;
      width: 100%;
      div{
        width: 50%;
      }
      .activeBox{
        height: 116px;
      }
    }


    .parkRightBox{
      .parkRimg{
        width: 100%;
        right: 0;
      }
    }
    //  ---------------------------
    .parkIntroduceimg{
      margin: 0;
    }
    .parkIntroduceBox{
      display: block;
      .parkIntroduce{
        width: 100%;
        h6{
          font-size: 39px;
          font-weight: bold;
          padding-bottom: 10px;
        }
      p{
         font-size: 34px;
        line-height: 44px;
      }
      }
    }
    .parkTitle{
      h5{
        font-size: 39px;
        line-height: 49px;
      }
      h6{
        font-size: 39px;
        line-height: 49px;
      }
    }
    .parkTarget{
      //padding-bottom: 76px;
      img{
        width: 120px;
        height: 120px;
      }
      p{
        font-size: 34px;
      }

    }
    .advantageBox{
      padding-bottom: 60px;
    }
    .advantageUl{
      flex-wrap: wrap;
    }
    .advantageUl li{
      width: 48%;
      margin-bottom: 40px;
      h6{
        font-size: 39px;
        line-height: 49px;
      }
      p{
        font-size: 34px;
        line-height: 44px;
        text-indent: 2em;
      }
    }
    .servceBox {
      background: url('../../assets/image/park/service14.png') no-repeat;
      background-size: cover;
    }
    .servceAll li p{
      font-size: 34px;
    }
    .characteristicCon{
      display: block;

      .characteristicLeft{
        width: 100%;
        p{
          font-size: 34px;
          line-height: 44px;
        }
      }
    }
    .characteristicRight{
      width: 100%;
      li{
        p{
          font-size: 34px;
          line-height: 44px;
        }
      }
    }
    .brandSettleIn {
      .parkTitle{
        text-align: center!important;
      }
      .parkBrand{
        margin-top: 20px;
        padding-top: 200px;
        position: relative;
        justify-content: center;
      }
      .brand-desc {
        width: 100%;
        position:absolute;
        top:10px;
        font-size: 34px;
        line-height: 44px;
      }
    }
  }
</style>
